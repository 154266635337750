import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import { Link, useHistory } from "react-router-dom";
import SkeletonWrapper from "./components/SkeltonWrapper";
import { Anchor, Box, Button, DataChart, DataTable, Distribution, Grid, Heading, ResponsiveContext, Text } from "grommet";

import _, { orderBy, toPairs } from "lodash";

import { ResolveUsers } from "./utils/IdentityAPI";

import XLSX from "xlsx";
import { useQuarantineSubjects } from "./utils/QuarantineStore";
import { format } from "date-fns";

const defaultColumns = [
    {
        property: "firstName",
        header: "First Name",
        search: true,
        sortable: true,
    },
    {
        property: "lastName",
        header: "Last Name",
        search: true,
        sortable: true,
    },
    {
        property: "group",
        header: "Group",
        render: (datum) => datum.groups.length > 0 && <Box pad={{ vertical: "xsmall" }}>{datum.groups[0].name}</Box>,
        search: true,
        sortable: true,
    },
    {
        property: "dateIn",
        header: "Date In",
        search: true,
        sortable: true,
        render: (datum) => format(new Date(datum.dateIn), "yyyy/MM/dd"),
    },
    {
        property: "dateOut",
        header: "Date Out",
        search: true,
        sortable: true,
        render: (datum) => format(new Date(datum.dateOut), "yyyy/MM/dd"),
    },
];

let Quarantine = () => {
    const [loading, setLoading] = React.useState(true);

    const [allReport, setAllReport] = React.useState([]);
    const [inTodayReport, setInTodayReport] = React.useState([]);
    const [outTodayReport, setOutTodayReport] = React.useState([]);

    let quarantine = useQuarantineSubjects();

    let [columns, setColumns] = React.useState(defaultColumns);

    React.useEffect(() => {
        if (quarantine.all.length > 0 || quarantine.inToday.length > 0 || quarantine.outToday.length > 0) {
            let allUsers = [].concat(quarantine.all).concat(quarantine.outToday);

            ResolveUsers(allUsers.map((s) => s.userID)).then((result) => {
                let mapSubjectToUser = (subjects) => {
                    let data = subjects.map((q) => {
                        let user = result.filter((u) => u.id == q.userID)[0];

                        return { firstName: user.firstName, lastName: user.lastName, groups: user.groups, ...q };
                    });

                    return orderBy(data, ["dateOut", "lastName"], ["asc", "asc"]);
                };

                setAllReport(mapSubjectToUser(quarantine.all));
                setInTodayReport(mapSubjectToUser(quarantine.inToday));
                setOutTodayReport(mapSubjectToUser(quarantine.outToday));

                setLoading(false);
            });
        }
    }, [quarantine]);

    let exportData = () => {
        var wb = XLSX.utils.book_new();

        let addSheet = (report, name) => {
            let aoa = report.map((r) => {
                let data = [r.firstName, r.lastName, r.groups.length > 0 && r.groups[0].name, r.dateIn, r.dateOut];
                return data;
            });

            aoa = [columns.map((c) => c.header)].concat(aoa);

            var ws = XLSX.utils.aoa_to_sheet(aoa);
            XLSX.utils.book_append_sheet(wb, ws, name);
        };

        allReport.length > 0 && addSheet(allReport, "In Quarantine");
        inTodayReport.length > 0 && addSheet(inTodayReport, "In Today");
        outTodayReport.length > 0 && addSheet(outTodayReport, "Out Today");

        XLSX.writeFile(wb, "export.xlsx");
    };

    return (
        <div>
            <h2 style={{ minWidth: "100px" }}>
                <Link to="/" component={Anchor}>
                    {/* @ts-ignore */}
                    <FontAwesomeIcon icon={["fad", "circle-chevron-left"]}></FontAwesomeIcon>
                </Link>{" "}
                <span style={{ marginLeft: "1rem" }}>Quarantine Report</span>
            </h2>
            <SkeletonWrapper loading={loading}>
                <div>
                    <Button primary label="Download" icon={<FontAwesomeIcon color="white" icon={["fad", "download"]}></FontAwesomeIcon>} onClick={exportData}></Button>
                </div>
                {allReport.length > 0 && (
                    <>
                        <Heading level="3">In Quarantine</Heading>
                        <DataTable primaryKey="id" columns={columns} data={allReport} sortable={true} />
                    </>
                )}
                {inTodayReport.length > 0 && (
                    <>
                        <Heading level="3">In Today</Heading>
                        <DataTable primaryKey="id" columns={columns} data={inTodayReport} sortable={true} />
                    </>
                )}
                {outTodayReport.length > 0 && (
                    <>
                        <Heading level="3">Out Today</Heading>
                        <DataTable primaryKey="id" columns={columns} data={outTodayReport} sortable={true} />
                    </>
                )}
            </SkeletonWrapper>
        </div>
    );
};

export default Quarantine;
