import axios from "./HttpClient";

const identityURL = IdentityServerUrl;

const COVID_APP_TYPE = 5;

export function GetUserInfo() {
    return axios.get(`${identityURL}connect/userinfo`);
}

export function GetMyApplications(user) {
    let headers = { "x-sca-ide-org-context": user.getClaim("sca-organization") };

    if (!user.getClaim("sca-organization")) {
        delete headers["x-sca-ide-org-context"];
    }

    return axios.get(`${identityURL}api/Applications/GetMyApplications`, { params: { type: COVID_APP_TYPE }, headers });
}

export function ResolveUsers(users) {
    return axios.post(`${identityURL}api/Users/$resolve`, { users }).then((response) => {
        return response.data;
    });
}

export function SearchUsers(user, query) {
    let headers = { "x-sca-ide-org-context": user.getClaim("sca-organization") };

    if (!user.getClaim("sca-organization")) {
        delete headers["x-sca-ide-org-context"];
    }

    return axios.get(`${identityURL}api/Users/searchUsers`, { params: { query }, headers }).then((response) => {
        return response.data;
    });
}

export function GetIdentityGroups(organizationId) {
    return axios.get(`${identityURL}api/Groups/${organizationId}`).then((response) => {
        return response.data;
    });
}

export function GetIdentityGroupMembers(groupId) {
    return axios.get(`${identityURL}api/Groups/${groupId}/members`).then((response) => {
        return response.data;
    });
}
