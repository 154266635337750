import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import { useCampaign } from "../utils/CampaignStore";
import { GetCampaignSubmissions, QuestionnaireActions, StartQuestionnaire } from "../utils/ServiceManagerAPI";

import { Link, useHistory } from "react-router-dom";
import SkeletonWrapper from "../components/SkeltonWrapper";
import { Anchor, Box, Button, DataChart, DataTable, Distribution, Grid, ResponsiveContext, Text } from "grommet";

import _, { orderBy, toPairs } from "lodash";

import { ResolveUsers } from "../utils/IdentityAPI";

import XLSX from "xlsx";

const defaultColumns = [
    {
        property: "date",
        header: "Date",
        search: true,
        sortable: true,
    },
    {
        property: "firstName",
        header: "First Name",
        search: true,
        sortable: true,
    },
    {
        property: "lastName",
        header: "Last Name",
        search: true,
        sortable: true,
    },
    {
        property: "group",
        header: "Group",
        render: (datum) => datum.groups.length > 0 && <Box pad={{ vertical: "xsmall" }}>{datum.groups[0].name}</Box>,
        search: true,
        sortable: true,
    },
];

const determineQuestionColor = (q) => {
    if (q.key == "temperature") {
        return "";
    } else {
        if (q.answer == "Yes") {
            return "status-error";
        } else if (q.answer == "No") {
            return "status-ok";
        }
    }
};

let SubmissionReport = () => {
    const [submissions, setSubmissions] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    let campaign = useCampaign();

    const [questions, setQuestions] = React.useState([]);
    const [report, setReport] = React.useState([]);

    let [columns, setColumns] = React.useState(defaultColumns);

    React.useEffect(() => {
        if (campaign.id) {
            GetCampaignSubmissions(campaign.id).then((result) => {
                setSubmissions(result.submissions);
                setQuestions(result.questions);
                setLoading(false);

                let newColumns = [...defaultColumns].concat(
                    _(result.questions)
                        .orderBy([(q) => q.key != "temperature", "sortKey"])
                        .map((q) => ({
                            property: "questions." + q.key + ".answer",
                            header: q.text,
                            render: (data) => <Text color={determineQuestionColor(data.questions[q.key])}>{data.questions[q.key].answer}</Text>,
                            search: false,
                            sortable: true,
                            size: "small",
                        }))
                        .value()
                );

                setColumns(newColumns);
            });
        }
    }, [campaign]);

    React.useEffect(() => {
        if (submissions.length > 0 && questions.length > 0) {
            ResolveUsers(submissions.map((s) => s.$subject)).then((result) => {
                let reportData = submissions.map((s) => {
                    let user = result.filter((u) => u.id == s.$subject)[0];

                    let userQuestions = _(questions)
                        .map((q) => {
                            return [
                                q.key,
                                {
                                    key: q.key,
                                    text: q.text,
                                    answer: s[q.key],
                                },
                            ];
                        })
                        .fromPairs()
                        .value();

                    return { key: user.id + s.$date, date: s.$date, ...user, questions: userQuestions };
                });

                setReport(orderBy(reportData, ["date", "lastName"], ["asc", "asc"]));
            });
        }
    }, [submissions, questions]);

    let exportData = () => {
        let aoa = report.map((r) => {
            let data = [r.firstName, r.lastName, r.groups.length > 0 && r.groups[0].name];

            //@ts-ignore
            return data.concat(toPairs(r.questions).map((q) => q[1].answer));
        });

        aoa = [columns.map((c) => c.header)].concat(aoa);

        var ws = XLSX.utils.aoa_to_sheet(aoa);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");

        XLSX.writeFile(wb, "export.xlsx");
    };

    return (
        <div>
            <h2 style={{ minWidth: "100px" }}>
                <Link to="/report" component={Anchor}>
                    {/* @ts-ignore */}
                    <FontAwesomeIcon icon={["fad", "circle-chevron-left"]}></FontAwesomeIcon>
                </Link>{" "}
                <span style={{ marginLeft: "1rem" }}>Submissions Report</span>
            </h2>
            <SkeletonWrapper loading={loading}>
                <div>
                    <Button primary label="Download" icon={<FontAwesomeIcon color="white" icon={["fad", "download"]}></FontAwesomeIcon>} onClick={exportData}></Button>
                </div>
                <DataTable primaryKey="key" columns={columns} data={report} sortable={true} />
            </SkeletonWrapper>
        </div>
    );
};

export default SubmissionReport;
