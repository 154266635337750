import * as React from "react";
import { Box, Layer } from "grommet";

const StyledModal = ({ isOpen, setIsOpen, children }) => {
    if (isOpen)
        return (
            <Layer responsive={false} onEsc={() => setIsOpen(false)} onClickOutside={() => setIsOpen(false)}>
                <Box pad="medium">{children}</Box>
            </Layer>
        );

    return <></>;
};

export default StyledModal;
