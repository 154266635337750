import { AccountInfo } from "@azure/msal-browser";
import { Campaign } from "./CampaignStore";
import axios from "./HttpClient";
import { GetUserInfo } from "./IdentityAPI";

const serviceManagerURL = ServiceManagerUrl;

export function GetCampaign(campaignId) {
    return axios.get(`${serviceManagerURL}api/Campaigns/${campaignId}`);
}

export function StartQuestionnaire(campaign: Campaign) {
    return GetUserInfo().then((res) => {
        return axios
            .post(`${serviceManagerURL}api/questionnaires`, {
                campaignID: campaign.id,
                organizationID: campaign.organizationID,
                questionnaireTypeID: campaign.questionnaireTypeID,
                // @ts-ignore
                subjectID: res.data.sub,
                subjectType: campaign.questionnaireType.subjectType,
                allowComments: campaign.allowComments,
                allowUploads: campaign.allowUploads,
            })
            .then((response) => {
                return response.data.result;
            });
    });
}

export function StartImpersonateQuestionnaire(campaign: Campaign, subject) {
    return axios
        .post(`${serviceManagerURL}api/questionnaires`, {
            campaignID: campaign.id,
            organizationID: campaign.organizationID,
            questionnaireTypeID: campaign.questionnaireTypeID,
            // @ts-ignore
            subjectID: subject,
            subjectType: campaign.questionnaireType.subjectType,
            allowComments: campaign.allowComments,
            allowUploads: campaign.allowUploads,
        })
        .then((response) => {
            return response.data.result;
        });
}

export function QuestionnaireActions() {
    return axios.get(`${serviceManagerURL}api/questionnaires/myactions`).then((response) => {
        return response.data.result;
    });
}

export function GetQuestionnaire(id) {
    return axios.get(`${serviceManagerURL}api/questionnaires/${id}`).then((response) => {
        return response.data.result;
    });
}

export function UploadAttachment(attachment) {
    return axios.post(`${serviceManagerURL}api/attachments`, attachment).then((response) => {
        return response.data.result;
    });
}

export function DeleteAttachment(attachmentId) {
    return axios.delete(`${serviceManagerURL}api/attachments/${attachmentId}`).then((response) => {
        return response.data.result;
    });
}

export function AnswerQuestion(questionnaire, question, set, option) {
    return axios.post(`${serviceManagerURL}api/answers/option`, option, {
        params: {
            questionnaire,
            question,
            set,
        },
    });
}

export function AddSet(questionnaireId, questionId) {
    return axios.post(`${serviceManagerURL}api/questionanswersets`, { questionId, questionnaireId }).then((response) => {
        return response.data.result;
    });
}

export function RemoveSet(setId) {
    return axios.delete(`${serviceManagerURL}api/questionanswersets/${setId}`).then((response) => {
        return response.data.result;
    });
}

export function SubmitQuestionnaire(id) {
    return axios.post(`${serviceManagerURL}api/questionnaires/$submit`, { questionnaireID: id }).then((response) => {
        return response.data.result;
    });
}

export function GetCampaignSubmissions(id) {
    return axios.get(`${serviceManagerURL}api/campaigns/${id}/$submissions`).then((response) => {
        return response.data.result;
    });
}
