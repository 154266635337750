import * as React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonWrapper = ({ loading, children }) => {
    if (loading) {
        return <Skeleton></Skeleton>;
    } else {
        return children;
    }
};

export default SkeletonWrapper;
