import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import MenuItem from "../components/MenuItem";

import { useCampaign } from "../utils/CampaignStore";
import { StartImpersonateQuestionnaire } from "../utils/ServiceManagerAPI";

import { Link, useHistory } from "react-router-dom";
import SkeletonWrapper from "../components/SkeltonWrapper";
import { Anchor, Box, Heading, TextInput } from "grommet";
import StyledModal from "../components/StyledModel";
import { useUser } from "../utils/AccountStore";
import { GetIdentityGroupMembers, GetIdentityGroups, SearchUsers } from "../utils/IdentityAPI";
import useLocalStorage from "../utils/LocalStorage";

let SearchGroups = () => {
    let [loading, setLoading] = React.useState(true);
    let [loadingGroups, setLoadingGroups] = React.useState(true);
    let [loadingMembers, setLoadingMembers] = React.useState(false);
    let [starting, setStarting] = React.useState(false);

    let [groupQuery, setGroupQuery] = useLocalStorage("groupsQuery", "");
    let [selectedGroup, setSelectedGroup] = useLocalStorage("selectedGroup", "");
    let [groupsResult, setGroupsResult] = React.useState([]);
    let [filteredGroupsResult, setFilteredGroupsResult] = React.useState([]);
    let [groupMembers, setGroupMembers] = React.useState([]);

    let user = useUser();
    let campaign = useCampaign();
    let history = useHistory();

    React.useEffect(() => {
        if (campaign.id) {
            setLoading(false);
            setLoadingGroups(true);
            GetIdentityGroups(user.getOrganization()).then((response) => {
                setLoadingGroups(false);
                setGroupsResult(response);
            });
        }
    }, [campaign]);

    React.useEffect(() => {
        if (selectedGroup) {
            setLoadingMembers(true);
            GetIdentityGroupMembers(selectedGroup).then((response) => {
                setLoadingMembers(false);
                setGroupMembers(response);
            });
        }
    }, [selectedGroup]);

    React.useEffect(() => {
        setFilteredGroupsResult(
            groupsResult.filter((group) => {
                let filterRegex = new RegExp(groupQuery, "i");
                return filterRegex.test(group.name);
            })
        );
    }, [groupsResult, groupQuery]);

    let startImpersonateScreening = (subject) => () => {
        setStarting(true);
        StartImpersonateQuestionnaire(campaign, subject).then((id) => {
            //@ts-ignore
            history.push(`/screen/${id}`);
        });
    };

    return (
        <div>
            <h2 style={{ minWidth: "100px" }}>
                <Link to="/impersonate" component={Anchor}>
                    {/* @ts-ignore */}
                    <FontAwesomeIcon icon={["fad", "circle-chevron-left"]}></FontAwesomeIcon>
                </Link>{" "}
                <span style={{ marginLeft: "1rem" }}>Group Search</span>
            </h2>
            <SkeletonWrapper loading={loading}>
                <TextInput
                    type="text"
                    autoComplete={"on"}
                    style={{ minHeight: "34px", maxHeight: "150px", height: "34px" }}
                    name="groupQuery"
                    value={groupQuery}
                    onChange={(e) => {
                        setGroupQuery(e.target.value);
                    }}
                />
                <SkeletonWrapper loading={loadingGroups}>
                    <Heading level={3}>Groups</Heading>
                    <div style={{ display: "flex" }}>
                        {filteredGroupsResult.map((group) => (
                            <div key={group.id} onClick={() => setSelectedGroup(group.id)}>
                                <MenuItem name={group.name} description={"Screen"} image={"/images/groups.png"} active={group.id == selectedGroup}></MenuItem>
                            </div>
                        ))}
                    </div>
                    <SkeletonWrapper loading={loadingMembers}>
                        <Heading level={3}>Members</Heading>
                        <div style={{ display: "flex" }}>
                            {groupMembers.map((user) => (
                                <div key={user.id} onClick={startImpersonateScreening(user.id)}>
                                    <MenuItem name={user.fullName} description={"Screen"} image={user.photo || "/images/screen.png"}></MenuItem>
                                </div>
                            ))}
                        </div>
                    </SkeletonWrapper>
                </SkeletonWrapper>
            </SkeletonWrapper>
            <StyledModal isOpen={starting} setIsOpen={setStarting}>
                <p>Please wait to start screening</p>
            </StyledModal>
        </div>
    );
};

export default SearchGroups;
