import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import { useCampaign, useQuestionnaire } from "./utils/CampaignStore";
import { AddSet, AnswerQuestion, DeleteAttachment, GetQuestionnaire, RemoveSet, SubmitQuestionnaire } from "./utils/ServiceManagerAPI";

import { Link, useHistory, useParams } from "react-router-dom";
import AnswerableQuestion from "./components/AnswerableQuestion";
import { useUser } from "./utils/AccountStore";

import SkeletonWrapper from "./components/SkeltonWrapper";
import { Anchor, Button } from "grommet";

import StyledModal from "./components/StyledModel";

let Questions = () => {
    //@ts-ignore
    let { id } = useParams();

    let [loading, setLoading] = React.useState(true);
    let [submitting, setSubmitting] = React.useState(false);

    let user = useUser();
    let campaign = useCampaign();
    let questionnaire = useQuestionnaire(id);

    let history = useHistory();

    const [questions, setQuestions] = React.useState([]);
    //const [questionnaire, setQuestionnaire] = React.useState({} as { id: string });

    React.useEffect(() => {
        //@ts-ignore
        if (questionnaire.id) {
            setQuestions(questionnaire.questions);
            setLoading(false);
        }
    }, [questionnaire]);

    const refreshQuestionnaire = () => {
        return GetQuestionnaire(id).then((result) => {
            setQuestions(result.questionnaire.questions);
        });
    };

    let handleOptionChange = (value, option, questionId, setId?) => {
        setQuestions(
            questions.map((q) => {
                if (q.id == questionId) {
                    if (q.allowSets) {
                        q.sets.forEach((s) => {
                            if (s.id == setId) {
                                s.options.forEach((o) => {
                                    if (o.id == option.id) {
                                        o.value = value;
                                    } else {
                                        if (o.type == 0 && !q.multipleOptions) {
                                            o.value = false;
                                        }
                                    }
                                });
                            }
                        });
                    } else {
                        q.options.forEach((o) => {
                            if (o.id == option.id) {
                                o.value = value;
                            } else {
                                if (o.type == 0 && !q.multipleOptions) {
                                    o.value = false;
                                }
                            }
                        });
                    }
                }
                return q;
            })
        );

        return AnswerQuestion(questionnaire.id, questionId, setId, option).then(refreshQuestionnaire);
    };

    let addSet = (questionId) => {
        return AddSet(questionnaire.id, questionId).then((res: any) => {
            setQuestions(
                questions.map((q) => {
                    if (q.id == questionId) {
                        let options = q.options.map((o) => {
                            let newOption = { ...o };
                            newOption.value = null;
                            return newOption;
                        });

                        res.options = options;
                        q.sets.push(res);
                    }
                    return q;
                })
            );
            return res;
        });
    };

    let deleteSet = (questionId, setId) => {
        return RemoveSet(setId).then(() => {
            setQuestions(
                questions.map((q) => {
                    if (q.id == questionId) {
                        q.sets = q.sets.filter((s) => s.id != setId);
                    }
                    return q;
                })
            );
        });
    };

    let attachmentUploaded = (attachment) => {
        setQuestions(
            questions.map((q) => {
                if (q.id == attachment.questionID) {
                    q.attachments.push(attachment);
                }
                return q;
            })
        );
    };

    let deleteAttachment = (questionId, attachmentId) => {
        DeleteAttachment(attachmentId).then(() => {
            setQuestions(
                questions.map((q) => {
                    if (q.id == questionId) {
                        q.attachments = q.attachments.filter((a) => a.id != attachmentId);
                    }
                    return q;
                })
            );
        });
    };

    let startSubmit = () => {
        setSubmitting(true);
    };

    let submit = () => {
        SubmitQuestionnaire(questionnaire.id).then(() => {
            setSubmitting(false);
            history.push(`/submissions/${questionnaire.id}`);
        });
    };

    return (
        <div>
            <h2 style={{ minWidth: "100px" }}>
                <Link to="/screen" component={Anchor}>
                    {/* @ts-ignore */}
                    <FontAwesomeIcon icon={["fad", "circle-chevron-left"]}></FontAwesomeIcon>
                </Link>{" "}
                <span style={{ marginLeft: "1rem" }}>Screening</span>
            </h2>
            <SkeletonWrapper loading={loading}>
                <div style={{ minHeight: "100px" }}>
                    {campaign && (
                        <div>
                            {questions.map((question, i) => (
                                <ConditionEvaluator key={i} question={question} questions={questions}>
                                    <AnswerableQuestion
                                        questionnaire={questionnaire}
                                        question={question}
                                        handleOptionChange={handleOptionChange}
                                        deleteSet={deleteSet}
                                        addSet={addSet}
                                        attachmentUploaded={attachmentUploaded}
                                        deleteAttachment={deleteAttachment}
                                        user={user}
                                        key={i}
                                    ></AnswerableQuestion>
                                </ConditionEvaluator>
                            ))}
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
                                <Button primary onClick={startSubmit} label="Submit"></Button>
                            </div>
                        </div>
                    )}
                </div>
                <StyledModal isOpen={submitting} setIsOpen={setSubmitting}>
                    <p>By clicking continue you hereby declare that the information provided above is true and correct.</p>
                    <Button label="Continue" onClick={submit}></Button>
                </StyledModal>
            </SkeletonWrapper>
        </div>
    );
};

function ConditionEvaluator({ question, questions, children }) {
    let show = false;

    if (question.conditions.length > 0) {
        question.conditions.forEach((condition) => {
            let reliesOn = questions.filter((q) => q.id == condition.reliesOnID)[0];

            if (reliesOn) {
                if (condition.type == 1 && reliesOn.answers && reliesOn.answers.length > 0) {
                    let result = false;

                    condition.conditionOptions.forEach((conditionOption) => {
                        reliesOn.options.forEach((option) => {
                            if (option.id == conditionOption.optionID && option.value) {
                                result = true;
                            }
                        });
                    });

                    show = show || result;
                }
            } else {
                show = false;
            }
        });
    } else {
        show = true;
    }

    return <>{show && children}</>;
}

export default Questions;
