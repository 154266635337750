import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import MenuItem from "../components/MenuItem";
import axios from "../utils/HttpClient";
import { useCampaign } from "../utils/CampaignStore";
import { GetCampaignSubmissions, QuestionnaireActions, StartQuestionnaire } from "../utils/ServiceManagerAPI";

import { utcToZonedTime } from "date-fns-tz";
import formatRelative from "date-fns/formatRelative";
import { Link, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import SkeletonWrapper from "../components/SkeltonWrapper";
import { Anchor, Box, Button, DataChart, Distribution, Grid, Heading, ResponsiveContext, Text } from "grommet";
import StyledModal from "../components/StyledModel";
import { useUser } from "../utils/AccountStore";
import { format } from "date-fns";

import _ from "lodash";
import { CreateTestReportData } from "../test/TestData";
import HoverButton from "../components/IconButton";
import { ResolveUsers } from "../utils/IdentityAPI";
import SubmissionReport from "./SubmissionReport";
import { useQuarantineSubjects } from "../utils/QuarantineStore";

const NGrid = ({ children, ...props }) => {
    const size = React.useContext(ResponsiveContext);

    let columns = size == "small" ? ["100%"] : ["50%", "50%"];

    return (
        <>
            <Grid columns={columns} {...props} gap="small">
                {children}
            </Grid>
        </>
    );
};

const LabeledButton = ({ children, ...props }) => {
    return <Button label={children} {...props}></Button>;
};

let ReportMain = () => {
    const [submissions, setSubmissions] = React.useState([]);
    const [questions, setQuestions] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [distribution, setDistribution] = React.useState([]);
    const [questionDistribution, setQuestionDistribution] = React.useState([]);

    let user = useUser();
    let campaign = useCampaign();
    let history = useHistory();
    let quarantine = useQuarantineSubjects();

    let GetQuestion = (key) => {
        return questions.filter((q) => q.key == key)[0];
    };

    React.useEffect(() => {
        if (campaign.id) {
            GetCampaignSubmissions(campaign.id).then((result) => {
                setSubmissions(result.submissions);
                setQuestions(result.questions);

                let questionKeys = [];

                var submissionData = _(result.submissions)
                    .orderBy(["$date"])
                    .filter((s) => s.$date)
                    .groupBy((s) => format(new Date(s.$date), "yyyy-MM-dd"))
                    .toPairs()
                    .map(([date, values]) => {
                        let chartData = values.reduce(
                            ({ minTemp, maxTemp, averageTemp, count, yes, no }, c) => {
                                _.keysIn(c)
                                    .filter((k) => k.indexOf("$") != 0 && k != "temperature")
                                    .forEach((q) => {
                                        if (c[q] == "No") {
                                            if (!no[q]) {
                                                questionKeys.push(q);
                                                no[q] = 1;
                                            } else {
                                                no[q]++;
                                            }

                                            no.count++;
                                        } else if (c[q] == "Yes") {
                                            if (!yes[q]) {
                                                questionKeys.push(q);
                                                yes[q] = 1;
                                            } else {
                                                yes[q]++;
                                            }
                                            yes.count++;
                                        }
                                    });

                                return {
                                    minTemp: Math.min(+c.temperature, minTemp),
                                    maxTemp: Math.max(+c.temperature, maxTemp),
                                    averageTemp: +((+c.temperature + count * averageTemp) / (count + 1)).toFixed(1),
                                    count: count + 1,
                                    yes,
                                    no,
                                };
                            },
                            {
                                minTemp: 100,
                                maxTemp: 0,
                                averageTemp: 0,
                                count: 0,
                                yes: { count: 0 },
                                no: { count: 0 },
                            }
                        );
                        return { date, ...chartData };
                    })
                    .value();

                if (submissionData.length == 1) {
                    submissionData = [submissionData[0], submissionData[0]];
                }

                // if (true) {
                //     //Test Data
                //     submissionData = CreateTestReportData();
                // }

                let distData = [];
                let questionDistData = [];

                questionKeys = _.uniq(questionKeys);

                questionDistData = questionKeys.map((q) => {
                    let res = {
                        key: q,
                        question: result.questions.filter((question) => question.key == q)[0],
                        distribution: [
                            { value: _.sumBy(submissionData, (d) => _.get(d.no, q, 0)), color: "status-ok", label: "No", key: q },
                            { value: _.sumBy(submissionData, (d) => _.get(d.yes, q, 0)), color: "status-error", label: "Yes", key: q },
                        ],
                    };
                    res.distribution = _.orderBy(res.distribution, ["value"], ["desc"]);

                    return res;
                });

                distData.push({ value: _.sumBy(submissionData, (d) => d.no.count), color: "status-ok", label: "No" });
                distData.push({ value: _.sumBy(submissionData, (d) => d.yes.count), color: "status-error", label: "Yes" });

                distData = _.orderBy(distData, ["value"], ["desc"]);

                setData(_.takeRight(submissionData, 10));
                setDistribution(distData);
                setQuestionDistribution(questionDistData);
                setLoading(false);
            });
        }
    }, [campaign]);

    let { path, url } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={path} exact>
                    <h2 style={{ minWidth: "100px" }}>
                        <Link to="/" component={Anchor}>
                            {/* @ts-ignore */}
                            <FontAwesomeIcon icon={["fad", "circle-chevron-left"]}></FontAwesomeIcon>
                        </Link>{" "}
                        <span style={{ marginLeft: "1rem" }}>Report</span>
                    </h2>
                    <SkeletonWrapper loading={loading}>
                        <Box fill align="center">
                            <Heading level="3" margin="small">
                                Total Screenings
                            </Heading>
                            <DataChart
                                size={{ width: "fill" }}
                                data={data}
                                series={[
                                    {
                                        property: "date",
                                        label: "Date",
                                        render: (value) => value.substring(5),
                                    },
                                    {
                                        property: "count",
                                        label: "Screenings",
                                        render: (value) => value.toFixed(0),
                                    },
                                ]}
                                chart="count"
                                axis={{ x: { property: "date", granularity: "medium" }, y: true }}
                                detail
                            />
                        </Box>
                        <Box fill align="center">
                            <Heading level="3" margin="small">
                                Temperature Trend
                            </Heading>

                            <DataChart
                                size={{ width: "fill" }}
                                data={data}
                                series={[
                                    {
                                        property: "date",
                                        label: "Date",
                                        render: (value) => value.substring(5),
                                    },
                                    {
                                        property: "averageTemp",
                                        label: "Temperature",
                                        render: (value) => value,
                                    },
                                ]}
                                bounds="align"
                                chart={[
                                    {
                                        property: ["minTemp", "maxTemp"],
                                        type: "area",
                                        thickness: "xsmall",
                                        color: "graph-3",
                                        opacity: "medium",
                                    },
                                    {
                                        property: "averageTemp",
                                        type: "line",
                                        thickness: "xxsmall",
                                        dash: true,
                                        round: true,
                                    },
                                    { property: "averageTemp", type: "point", thickness: "small" },
                                ]}
                                axis={{ x: { property: "date", granularity: "fine" }, y: { property: "averageTemp", granularity: "fine" } }}
                                guide={{ x: { granularity: "medium" }, y: { granularity: "fine" } }}
                                detail
                                gap="small"
                                pad="small"
                            />
                        </Box>
                        <NGrid>
                            <Box align="center" width="medium">
                                <Heading level="3" margin="small">
                                    Screening Questions
                                </Heading>

                                <Distribution basis="small" fill values={distribution} margin="small">
                                    {(value) => (
                                        <Box pad="medium" background={value.color} fill>
                                            <Text size="large">{value.label}</Text>
                                            <Text size="medium">{value.value}</Text>
                                        </Box>
                                    )}
                                </Distribution>
                                <Link component={LabeledButton} to={`${path}/submission`}>
                                    Screening Report
                                </Link>
                            </Box>
                            <Box align="center" width="medium">
                                <Heading level="3" margin="small">
                                    Quarantine
                                </Heading>

                                <Grid fill pad={{ left: "medium", right: "medium" }} columns={["1fr", "2fr"]}>
                                    <Box align="center">
                                        <Text size="medium">Total</Text>
                                        <Text size="xxlarge" margin="medium">
                                            {quarantine.all.length}
                                        </Text>
                                    </Box>
                                    <Box align="center">
                                        <Text size="medium">Today</Text>
                                        <Grid pad="medium" columns={["1fr", "1fr", "1fr"]} gap="small">
                                            {/* @ts-ignore */}
                                            <FontAwesomeIcon size="lg" icon={["fad", "house-medical"]}></FontAwesomeIcon>
                                            <Text size="medium" color="status-warning" textAlign="center">
                                                +{quarantine.inToday.length}
                                            </Text>
                                            <Text color="status-warning">new</Text>
                                            {/* @ts-ignore */}
                                            <FontAwesomeIcon size="lg" icon={["fad", "face-mask"]}></FontAwesomeIcon>
                                            <Text size="medium" color="status-ok" textAlign="center">
                                                -{quarantine.outToday.length}
                                            </Text>
                                            <Text color="status-ok">out</Text>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Link component={LabeledButton} to="/quarantine">
                                    Quarantine Report
                                </Link>
                            </Box>
                        </NGrid>

                        <NGrid>
                            {questionDistribution.map((q) => (
                                <Box key={q.key} align="center" width="medium" style={{ justifyContent: "space-between" }}>
                                    <h4>{q.question.text}</h4>
                                    <Distribution basis="small" fill values={q.distribution} margin="small">
                                        {(value) => (
                                            <Box pad="medium" background={value.color} fill>
                                                <Text size="large">{value.label}</Text>
                                                <Text size="medium">{value.value}</Text>
                                            </Box>
                                        )}
                                    </Distribution>
                                </Box>
                            ))}
                        </NGrid>
                    </SkeletonWrapper>
                </Route>
                <Route path={`${path}/submission`}>
                    <SubmissionReport></SubmissionReport>
                </Route>
            </Switch>
        </div>
    );
};

export default ReportMain;
