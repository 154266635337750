import * as React from "react";
import { useUser } from "./AccountStore";
import { GetMyApplications, GetUserInfo } from "./IdentityAPI";
import { GetCampaign, GetQuestionnaire } from "./ServiceManagerAPI";

export class Campaign {
    id: string;
    name: string;
    organizationID: string;
    key: string;
    questionnaireTypeID: string;
    questionnaireType: QuestionnaireType;
    delegated: boolean;
    allowComments: boolean;
    allowUploads: boolean;
    accessType: number;
    description: string;
    data: CampaignData;
    questionnaires?: Array<any>;
    startDate: string;
    endDate: string;
    flagDate: string;

    campaignFrequency: number;

    constructor(organizationId?: string) {
        this.organizationID = organizationId;
        this.data = new CampaignData();
        this.accessType = 3;
        this.name = "";
        this.description = "";
        this.key = "";
        this.delegated = false;
    }
}

export class QuestionnaireType {
    id: string;
    name: string;
    subjectType: number;
    organizationID: string;
}

export class CampaignData {
    constructor() {
        this.icon = "comments-question-check";
    }

    groups?: Array<string>;
    allowStaffAsSubjects?: boolean;
    allowStudentsAsSubjects?: boolean;
    allowParentsAsSubjects?: boolean;
    allowGuestsAsSubjects?: boolean;
    icon: string;
}

export function useCampaign() {
    const user = useUser();

    const [activeCampaignId, setActiveCampaignId] = React.useState("");
    const [activeCampaign, setActiveCampaign] = React.useState({} as Campaign);

    React.useEffect(() => {
        if (user.isAuthenticated) {
            GetMyApplications(user).then((res) => {
                if (res.data.length >= 1) {
                    setActiveCampaignId(res.data[0].configuration.campaignID);
                }
                //setCovidApps(res.data);
            });
        }
    }, [user]);

    React.useEffect(() => {
        if (activeCampaignId) {
            GetCampaign(activeCampaignId).then((res) => {
                setActiveCampaign(res.data.result);
            });
        }
    }, [activeCampaignId]);

    return activeCampaign;
}

export function useQuestionnaire(id) {
    const user = useUser();

    const [questionnaire, setQuestionnaire] = React.useState({} as { id: string; questions: any[]; timestamp: string });

    React.useEffect(() => {
        if (user.isAuthenticated) {
            GetQuestionnaire(id).then((res) => {
                setQuestionnaire(res.questionnaire);
            });
        }
    }, [user, id]);

    return questionnaire;
}

// let [submissions, setSubmissions] = React.useState([]);
// let [activeCampaign, setActiveCampaign] = React.useState({} as Campaign);
// let [covidApps, setCovidApps] = React.useState([]);
// let [loading, setLoading] = React.useState(true);

// React.useEffect(() => {
//     if (activeCampaign.id) {
//         axios.get(`https://localhost:44304/api/Campaigns/${activeCampaign.id}/$submissions`).then((res) => {
//             setSubmissions(res.data.result);
//         });
//     }
// }, [activeCampaign]);
