import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import MenuItem from "../components/MenuItem";
import { debounce } from "lodash";
import { useCampaign } from "../utils/CampaignStore";
import { QuestionnaireActions, StartImpersonateQuestionnaire, StartQuestionnaire } from "../utils/ServiceManagerAPI";

import { utcToZonedTime } from "date-fns-tz";
import formatRelative from "date-fns/formatRelative";
import { Link, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import SkeletonWrapper from "../components/SkeltonWrapper";
import { Anchor, Box, TextInput } from "grommet";
import StyledModal from "../components/StyledModel";
import { useUser } from "../utils/AccountStore";
import { GetIdentityGroupMembers, GetIdentityGroups, SearchUsers } from "../utils/IdentityAPI";
import useLocalStorage from "../utils/LocalStorage";

const searchUsers = (user, userQuery, setUsersResult) => {
    SearchUsers(user, userQuery).then((response) => {
        setUsersResult(response);
    });
};

let SearchIndividual = () => {
    let [loading, setLoading] = React.useState(true);
    let [userQuery, setUserQuery] = React.useState("");
    let [usersResult, setUsersResult] = React.useState([]);
    let [starting, setStarting] = React.useState(false);

    let user = useUser();
    let campaign = useCampaign();
    let history = useHistory();

    React.useEffect(() => {
        if (campaign.id) {
            setLoading(false);
        }
    }, [campaign]);

    const debouncedSearchUsers = React.useMemo(() => debounce(searchUsers, 500), []);

    React.useEffect(() => {
        return () => {
            debouncedSearchUsers.cancel();
        };
    }, []);

    React.useEffect(() => {
        if (userQuery.length >= 2) {
            debouncedSearchUsers(user, userQuery, (result) => setUsersResult(result));
        }
    }, [userQuery]);

    let startImpersonateScreening = (subject) => () => {
        setStarting(true);
        StartImpersonateQuestionnaire(campaign, subject).then((id) => {
            //@ts-ignore
            history.push(`/screen/${id}`);
        });
    };

    return (
        <div>
            <h2 style={{ minWidth: "100px" }}>
                <Link to="/impersonate" component={Anchor}>
                    {/* @ts-ignore */}
                    <FontAwesomeIcon icon={["fad", "circle-chevron-left"]}></FontAwesomeIcon>
                </Link>{" "}
                <span style={{ marginLeft: "1rem" }}>Individual Search</span>
            </h2>
            <SkeletonWrapper loading={loading}>
                <TextInput
                    type="text"
                    autoComplete={"on"}
                    style={{ minHeight: "34px", maxHeight: "150px", height: "34px" }}
                    name="userQuery"
                    value={userQuery}
                    onChange={(e) => {
                        setUserQuery(e.target.value);
                    }}
                />
                <div style={{ display: "flex" }}>
                    {usersResult.map((user) => (
                        <div key={user.id} onClick={startImpersonateScreening(user.id)}>
                            <MenuItem name={user.fullName} description={"Screen"} image={"/images/screen.png"}></MenuItem>
                        </div>
                    ))}
                </div>
            </SkeletonWrapper>

            <StyledModal isOpen={starting} setIsOpen={setStarting}>
                <p>Please wait to start screening</p>
            </StyledModal>
        </div>
    );
};

export default SearchIndividual;
