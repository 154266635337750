import * as React from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

import Home from "./Home";
import DarkModeSwitch, { useDarkMode } from "./components/DarkModeSwitch";
import Screening from "./Screening";
import Questions from "./Questions";
import { Box, Grommet } from "grommet";
import Theme from "./utils/Theme";
import { SkeletonTheme } from "react-loading-skeleton";
import { normalizeColor } from "grommet/utils";
import Submission from "./Submission";
import ReportMain from "./Report/ReportMain";
import Splash from "./Splash";
import Quarantine from "./Quarantine";
import Impersonate from "./Impersonate/ImpersonateMain";

type AppProps = {
    pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);

    const [isDarkMode, toggleDarkMode] = useDarkMode();
    const [containerSize, setContainerSize] = React.useState("large");
    let location = useLocation();
    React.useEffect(() => {
        if (location.pathname.indexOf("/report") == 0) {
            setContainerSize("");
        } else {
            setContainerSize("large");
        }
    }, [location]);

    return (
        <MsalProvider instance={pca}>
            <Grommet full theme={Theme} themeMode={isDarkMode ? "dark" : "light"}>
                <SkeletonTheme color={normalizeColor("background-back", Theme, isDarkMode)} highlightColor={normalizeColor("background-front", Theme, isDarkMode)}>
                    <DarkModeSwitch></DarkModeSwitch>
                    <Box align="center">
                        <Box width={containerSize} pad={{ left: "medium", right: "medium" }} style={{ position: "relative" }}>
                            <AuthenticatedTemplate>
                                <Switch>
                                    <Route path="/submissions/:id">
                                        <Submission></Submission>
                                    </Route>
                                    <Route path="/screen/:id">
                                        <Questions></Questions>
                                    </Route>
                                    <Route path="/screen">
                                        <Screening></Screening>
                                    </Route>

                                    <Route path="/report">
                                        <ReportMain></ReportMain>
                                    </Route>

                                    <Route path="/quarantine">
                                        <Quarantine></Quarantine>
                                    </Route>

                                    <Route path="/impersonate">
                                        <Impersonate></Impersonate>
                                    </Route>

                                    <Route path="/">
                                        <Home></Home>
                                    </Route>
                                </Switch>
                            </AuthenticatedTemplate>

                            <UnauthenticatedTemplate>
                                <Splash></Splash>
                            </UnauthenticatedTemplate>
                        </Box>
                    </Box>
                </SkeletonTheme>
            </Grommet>
        </MsalProvider>
    );
}

export default App;
