import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import MenuItem from "./components/MenuItem";
import axios from "./utils/HttpClient";
import { Link } from "react-router-dom";
import { useCampaign } from "./utils/CampaignStore";
import SkeletonWrapper from "./components/SkeltonWrapper";
import { Anchor } from "grommet";

let Home = () => {
    let [loading, setLoading] = React.useState(true);
    let campaign = useCampaign();

    React.useEffect(() => {
        if (campaign.id) {
            setLoading(false);
        }
    }, [campaign]);

    return (
        <div>
            {campaign && (
                <div>
                    <SkeletonWrapper loading={loading}>
                        <h2 style={{ minWidth: "100px" }}>
                            {/* @ts-ignore */}
                            {campaign.data && <FontAwesomeIcon icon={["fad", campaign.data.icon]}></FontAwesomeIcon>}
                            <span style={{ marginLeft: "1rem" }}>{campaign.name}</span>
                        </h2>
                        <div style={{ display: "flex" }}>
                            <Link to="/screen" component={Anchor}>
                                <MenuItem name="Start Screening" image="/images/screen.png" description=""></MenuItem>
                            </Link>
                            <Link to="/submissions" component={Anchor}>
                                <MenuItem name="Past Screenings" image="/images/submissions.png" description=""></MenuItem>
                            </Link>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Link to="/quarantine" component={Anchor}>
                                <MenuItem name="Quarantine" image="/images/quarantine.png" description=""></MenuItem>
                            </Link>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Link to="/report" component={Anchor}>
                                <MenuItem name="Reports" image="/images/reports.png" description=""></MenuItem>
                            </Link>
                        </div>
                    </SkeletonWrapper>
                </div>
            )}
        </div>
    );
};

export default Home;
