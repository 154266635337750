import { grommet } from "grommet";
import { deepMerge } from "grommet/utils";
import { ThemeType } from "grommet/themes";

const ssacovidtheme: ThemeType = {
    global: {
        colors: {
            /* BEGIN: Color Palette Definition */
            brand: "var(--accent)",
            "light-1": "#f7f7f7",
            "light-2": "#e1e1e1",
            "light-3": "#b1b1b1",
            "dark-1": "#626262",
            "dark-2": "#515151",
            "dark-3": "#3b3b3b",
            /* END: Color Palette Definition */
            /* BEGIN: Mapping Colors to Grommet Namespaces */
            background: {
                dark: "dark-1",
                light: "light-1",
            },
            "background-back": {
                dark: "dark-2",
                light: "light-2",
            },
            "background-front": {
                dark: "dark-3",
                light: "light-3",
            },
            control: {
                dark: "brand",
            },
        },
        focus: {
            border: {
                color: "gold",
            },
        },
        /* END: Mapping Colors to Grommet Namespaces */
    },
    /* BEGIN: Mapping Colors to Components */
    anchor: {
        color: "text",
        hover: {
            textDecoration: "none",
        },
    },
    button: {
        border: {
            radius: "4px",
        },
    },
    layer: {
        background: {
            dark: "background",
            light: "background",
        },
    },
    /* END: Mapping Colors to Components */
};

const Theme = deepMerge(grommet, ssacovidtheme);

export default Theme;
