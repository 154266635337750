import * as React from "react";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { Button } from "grommet";

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    };

    // React.useEffect(() => {
    //     let account = instance.getActiveAccount();
    //     if (!account) {
    //         instance.ssoSilent(loginRequest);
    //     }
    // }, []);

    return (
        <div>
            <Button onClick={() => handleLogin()} key="loginRedirect" label="Sign In" size="large" margin="large"></Button>
        </div>
    );
};
