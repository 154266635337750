import { Configuration, PopupRequest, ProtocolMode, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        clientId: IdentityClientID,
        // secrect:'RrCu0QvIUz8JjAVQ7zKNEynqTllDHkgZ-2R3p3bin-wmvhy1wzeFhwvaKciQH84LW6Zke4qYjwbk5nAo84trYA==',
        authority: IdentityServerUrl,
        knownAuthorities: [IdentityAuthority],
        protocolMode: ProtocolMode.OIDC,
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["openid", "profile", "default", "identity", "offline_access"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};
