import * as React from "react";
import { useUser } from "./AccountStore";
import { ListQuarantineSubjects } from "./PresenceAPI";

export function useQuarantineSubjects() {
    const user = useUser();

    const [quarantineSubjects, setQuarantineSubjects] = React.useState({ all: [], inToday: [], outToday: [] });

    React.useEffect(() => {
        if (user.isAuthenticated) {
            ListQuarantineSubjects().then((res) => {
                setQuarantineSubjects(res);
            });
        }
    }, [user]);

    return quarantineSubjects;
}
