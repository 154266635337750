import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UploadAttachment } from "../utils/ServiceManagerAPI";
import { FileInput } from "grommet";

const { Component } = React;

interface Attachment {
    id?: string;
    name: string;
    questionId: string;
    questionnaireId: string;
    content: string;
    contentType: string;
}

interface AttachmentUploadState {
    id: string;
    // attachment: Attachment,
    isLoaded: boolean;
}

interface AttachmentUploadProps {
    questionId: string;
    questionnaireId: string;
    done: Function;
}

export default class AttachmentUpload extends Component<AttachmentUploadProps, AttachmentUploadState> {
    constructor(props) {
        super(props);
        this.state = {
            id: Math.floor(Math.random() * 100000).toString(),
            isLoaded: false,
        };
    }

    componentDidMount() {}

    handleContentChange = (e) => {
        let content = e.target.files[0];

        if (content) {
            var reader = new FileReader();
            reader.readAsDataURL(content);
            reader.onload = (evt: any) => {
                UploadAttachment({
                    content: evt.target.result.replace(/^data:(\w+|\-|\/)+;base64,/, ""),
                    contentType: content.type,
                    name: content.name,
                    questionId: this.props.questionId,
                    questionnaireId: this.props.questionnaireId,
                }).then((res) => this.props.done(res));
            };
        }
    };

    render() {
        return (
            //@ts-ignore
            <label className="btn btn-outline-primary" for={this.state.id} style={{ marginTop: "7px", marginRight: "4px" }}>
                <FontAwesomeIcon icon={["fad", "upload"]}></FontAwesomeIcon>
                <div className="d-none">
                    <FileInput id={this.state.id} type="file" onChange={this.handleContentChange} />
                </div>
            </label>
        );
    }
}
