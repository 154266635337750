import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import { useCampaign, useQuestionnaire } from "./utils/CampaignStore";
import { AddSet, AnswerQuestion, DeleteAttachment, GetQuestionnaire, RemoveSet, SubmitQuestionnaire } from "./utils/ServiceManagerAPI";

import { Link, useHistory, useParams } from "react-router-dom";
import AnswerableQuestion from "./components/AnswerableQuestion";
import { useUser } from "./utils/AccountStore";

import SkeletonWrapper from "./components/SkeltonWrapper";
import { Anchor, Box, Button, Heading, Image, Text } from "grommet";

import StyledModal from "./components/StyledModel";
import { addHours } from "date-fns";
import { curry } from "lodash";
import { QuarantineMe } from "./utils/PresenceAPI";
import { isSameDay } from "date-fns/esm";

let Submission = () => {
    //@ts-ignore
    let { id } = useParams();

    let [loading, setLoading] = React.useState(true);
    let [conditionsMet, setConditionsMet] = React.useState(false);
    let [temperature, setTemperature] = React.useState(0);

    let user = useUser();
    let campaign = useCampaign();
    let questionnaire = useQuestionnaire(id);
    let history = useHistory();

    React.useEffect(() => {
        if (questionnaire.id && questionnaire.questions.length > 0) {
            setConditionsMet(
                questionnaire.questions.reduce((agg, cur) => {
                    return (
                        agg &&
                        cur.options.reduce((a, c) => {
                            return a || (c.type == 0 && c.text == "No" && c.value == "true") || (c.type == 2 && true); //c.value < "38")
                        }, false)
                    );
                }, true)
            );

            setTemperature(questionnaire.questions.filter((q) => q.key == "temperature")[0].options[0].value);

            setLoading(false);
        }
    }, [questionnaire]);

    React.useEffect(() => {
        if (!loading && !conditionsMet && isSameDay(new Date(questionnaire.timestamp), new Date())) {
            QuarantineMe();
        }
    }, [loading, conditionsMet]);

    return (
        <div>
            <h2 style={{ minWidth: "100px" }}>
                <Link to="/screen" component={Anchor}>
                    {/* @ts-ignore */}
                    <FontAwesomeIcon icon={["fad", "circle-chevron-left"]}></FontAwesomeIcon>
                </Link>{" "}
                <span style={{ marginLeft: "1rem" }}>Confirmation</span>
            </h2>
            <SkeletonWrapper loading={loading}>
                <div style={{ minHeight: "100px" }}>
                    {campaign && (
                        <Box align="center">
                            {conditionsMet && (
                                <>
                                    <Image margin="medium" alignSelf="center" fit="contain" src="/images/success.png" width="200" />
                                    <Heading size="small" color="status-ok">
                                        Success
                                    </Heading>

                                    <Box align="center" width="small">
                                        <Image margin="small" alignSelf="center" fit="contain" src="/images/mask.png" width="70" />
                                        <Text textAlign="center">Wearing a mask is compulsory at all times</Text>
                                    </Box>
                                    <Box>
                                        <Image margin="small" alignSelf="center" fit="contain" src="/images/screen.png" width="70" />
                                        <Text textAlign="center" size="large">
                                            {temperature}°
                                        </Text>
                                    </Box>
                                    {false && (
                                        <Box>
                                            <Image margin="small" alignSelf="center" fit="contain" src="/images/vaccine.png" width="70" />
                                            <Text textAlign="center" size="medium">
                                                Vaccinated
                                            </Text>
                                        </Box>
                                    )}
                                </>
                            )}
                            {!conditionsMet && (
                                <>
                                    <Image margin="medium" alignSelf="center" fit="contain" src="/images/isolate.png" width="200" />
                                    <Heading size="small" color="status-warning">
                                        Warning
                                    </Heading>
                                    <Box align="center" width="small">
                                        <Image margin="small" alignSelf="center" fit="contain" src="/images/quarantine.png" width="70" />
                                        <Text size="small" textAlign="center">
                                            You have been placed in quarantine for 10 days, please wait 10 days or upload a negative COVID-19 test to be removed from quarantine.
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Image margin="small" alignSelf="center" fit="contain" src="/images/screen.png" width="70" />
                                        <Text textAlign="center" size="large">
                                            {temperature}°
                                        </Text>
                                        <Text textAlign="center" size="small">
                                            You temperature
                                        </Text>
                                    </Box>
                                </>
                            )}

                            <Button
                                label="Home"
                                size="large"
                                margin={{ top: "large" }}
                                onClick={() => {
                                    history.push("/");
                                }}
                            ></Button>
                            {user.hasPermission("covid:impersonateScreening") && (
                                <Button
                                    label="Screen Someone Else"
                                    size="large"
                                    margin={{ top: "large" }}
                                    onClick={() => {
                                        history.push("/impersonate/groups");
                                    }}
                                ></Button>
                            )}
                        </Box>
                    )}
                </div>
            </SkeletonWrapper>
        </div>
    );
};

export default Submission;
