import axios from "./HttpClient";

import * as React from "react";
import { GetUserInfo } from "./IdentityAPI";
import { singletonHook } from "react-singleton-hook";
import { isArray, toPairs, some } from "lodash";

const ROLE_CLAIM = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
const NAME_CLAIM = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
const QUESTIONNAIRE_CLAIM = "sca-ser-questionnaire";
const QUESTIONNAIRE_TYPE_CLAIM = "sca-ser-questionnaire-type";
const PERMISSION_CLAIM = "sca-ide-permission";
const CAMPAIGN_CLAIM = "sca-ser-campaign";
const ORGANIZATION_CLAIM = "sca-organization";
export interface Claim {
    type: string;
    value: string;
}

export class Account {
    isAuthenticated: boolean;
    name: string;
    claims: Array<Claim>;

    constructor(account) {
        this.isAuthenticated = account.isAuthenticated;
        this.name = account.name;
        this.claims = account.claims || [];
    }

    isSingleOrganizationUser() {
        return this.claims.filter((c) => c.type == ORGANIZATION_CLAIM).length == 1 && !this.hasRole("Admin");
    }

    hasRole(role) {
        return this.claims.filter((c) => c.type == ROLE_CLAIM && this.claimValueEquals(c.value, role)).length > 0;
    }

    getClaim(claim) {
        let matched = this.claims.filter((c) => c.type == claim);
        if (matched.length > 0) {
            return matched[0].value;
        }
        return null;
    }

    getOrganization() {
        return this.getClaim(ORGANIZATION_CLAIM);
    }

    claimValueEquals(claimValue, comparison) {
        if (isArray(claimValue)) {
            return some(claimValue, (value) => value == comparison);
        }
        return claimValue == comparison;
    }

    hasQuestionnaireAccess(questionnaire, ...accessType) {
        return (
            questionnaire &&
            (this.hasRole("Admin") ||
                this.claims
                    .filter((c) => c.type == QUESTIONNAIRE_CLAIM && c.value.indexOf(questionnaire) == 0)
                    .map((c) => +c.value.substring(c.value.indexOf(":") + 1))
                    .filter((c) => accessType.some((a) => (c | a) == c)).length > 0)
        );
    }

    hasAnyQuestionnaireAccess(...accessType) {
        return (
            this.hasRole("Admin") ||
            this.claims
                .filter((c) => c.type == QUESTIONNAIRE_CLAIM)
                .map((c) => +c.value.substring(c.value.indexOf(":") + 1))
                .filter((c) => accessType.some((a) => (c | a) == c)).length > 0
        );
    }

    hasQuestionnaireTypeAccess(questionnaireType, ...accessType) {
        return (
            questionnaireType &&
            (this.hasRole("Admin") ||
                this.claims
                    .filter((c) => c.type == QUESTIONNAIRE_TYPE_CLAIM && c.value.indexOf(questionnaireType) == 0)
                    .map((c) => +c.value.substring(c.value.indexOf(":") + 1))
                    .filter((c) => accessType.some((a) => (c | a) == c)).length > 0)
        );
    }

    hasAnyQuestionnaireTypeAccess(...accessType) {
        return (
            this.hasRole("Admin") ||
            this.claims
                .filter((c) => c.type == QUESTIONNAIRE_TYPE_CLAIM)
                .map((c) => +c.value.substring(c.value.indexOf(":") + 1))
                .filter((c) => accessType.some((a) => (c | a) == c)).length > 0
        );
    }

    hasCampaignAccess(campaign, ...accessType) {
        return (
            campaign &&
            (this.hasRole("Admin") ||
                this.claims
                    .filter((c) => c.type == CAMPAIGN_CLAIM && c.value.indexOf(campaign) == 0)
                    .map((c) => +c.value.substring(c.value.indexOf(":") + 1))
                    .filter((c) => accessType.some((a) => (c | a) == c)).length > 0)
        );
    }

    hasAnyCampaignAccess(...accessType) {
        return (
            this.hasRole("Admin") ||
            this.claims
                .filter((c) => c.type == CAMPAIGN_CLAIM)
                .map((c) => +c.value.substring(c.value.indexOf(":") + 1))
                .filter((c) => accessType.some((a) => (c | a) == c)).length > 0
        );
    }

    hasPermission(permission) {
        var organization = this.getClaim(ORGANIZATION_CLAIM);
        return (
            this.hasRole("Admin") ||
            this.claims
                .filter((c) => c.type == PERMISSION_CLAIM)
                .map((c) => c.value)
                .filter((c) => c == permission + ":" + organization).length > 0
        );
    }
}

const init = new Account({});

const userFactory = () => {
    const [user, setUser] = React.useState(init);

    React.useEffect(() => {
        GetUserInfo().then((res) => {
            let claims = toPairs(res.data).map(([k, v]) => ({
                type: k,
                value: v,
            }));

            let nameClaim = res.data[NAME_CLAIM];
            let name = nameClaim;

            if (isArray(nameClaim)) {
                name = nameClaim[0];
            }
            setUser(new Account({ isAuthenticated: true, claims, name }));
        });
    }, []);

    return user;
};

export const useUser = singletonHook(init, userFactory);
