import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import MenuItem from "./components/MenuItem";
import axios from "./utils/HttpClient";
import { useCampaign } from "./utils/CampaignStore";
import { QuestionnaireActions, StartQuestionnaire } from "./utils/ServiceManagerAPI";

import { utcToZonedTime } from "date-fns-tz";
import formatRelative from "date-fns/formatRelative";
import { Link, useHistory } from "react-router-dom";
import SkeletonWrapper from "./components/SkeltonWrapper";
import { Anchor, Box } from "grommet";
import StyledModal from "./components/StyledModel";
import { useUser } from "./utils/AccountStore";

let Screening = () => {
    let [loading, setLoading] = React.useState(true);
    let [starting, setStarting] = React.useState(false);
    let [actions, setActions] = React.useState([]);
    let user = useUser();
    let campaign = useCampaign();
    let history = useHistory();

    React.useEffect(() => {
        if (campaign.id) {
            setLoading(false);
        }
    }, [campaign]);

    React.useEffect(() => {
        if (campaign.id) {
            QuestionnaireActions().then((result) => {
                setActions(result.filter((a) => (a.type == 1 && a.data.campaignID == campaign.id) || (a.type == 2 && a.data.id == campaign.id)));
            });
        }
    }, [campaign]);

    let startScreening = () => {
        setStarting(true);
        StartQuestionnaire(campaign).then((id) => {
            //@ts-ignore
            history.push(`/screen/${id}`);
        });
    };

    let prettyDate = (d) => {
        const date = new Date(d);

        return formatRelative(date, new Date());
    };

    return (
        <div>
            <h2 style={{ minWidth: "100px" }}>
                <Link to="/" component={Anchor}>
                    {/* @ts-ignore */}
                    <FontAwesomeIcon icon={["fad", "circle-chevron-left"]}></FontAwesomeIcon>
                </Link>{" "}
                <span style={{ marginLeft: "1rem" }}>Screening</span>
            </h2>
            <SkeletonWrapper loading={loading}>
                <div style={{ minHeight: "100px" }}>
                    {campaign && (
                        <div style={{ display: "flex" }}>
                            {user.hasPermission("covid:impersonateScreening") && (
                                <Link to={`/impersonate`} component={Anchor}>
                                    <MenuItem name={"Screen Others"} description={"Screening"} image={"/images/search.png"}></MenuItem>
                                </Link>
                            )}
                            {actions.map((action, i) => (
                                <div key={i}>
                                    {action.type == 2 && (
                                        <div onClick={startScreening}>
                                            <MenuItem name={"Start"} description={"My Screening"} image={"/images/start.png"}></MenuItem>
                                        </div>
                                    )}
                                    {action.type == 1 && (
                                        <Link to={`/screen/${action.data.id}`} component={Anchor}>
                                            <MenuItem name={action.text} description={action.data.name} image={"/images/screen.png"} subtext={prettyDate(action.data.timestamp)}></MenuItem>
                                        </Link>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                    <StyledModal isOpen={starting} setIsOpen={setStarting}>
                        <p>Please wait to start screening</p>
                    </StyledModal>
                </div>
            </SkeletonWrapper>
        </div>
    );
};

export default Screening;
