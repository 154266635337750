import { Box, Heading, Image } from "grommet";
import * as React from "react";

import { SignInButton } from "./SignInButton";

const Splash = () => {
    return (
        <Box align="center">
            <Image margin="small" alignSelf="center" fit="contain" src="/images/pandemic.png" width="150" />
            <Heading level={2}>Pandemic Screening</Heading>
            <SignInButton></SignInButton>
            <Box direction="row" margin={{ top: "medium" }}>
                <Image margin="small" alignSelf="center" fit="contain" src="/images/shield-immune.png" width="100" />
                <Image margin="small" alignSelf="center" fit="contain" src="/images/shield-tips.png" width="100" />
                <Image margin="small" alignSelf="center" fit="contain" src="/images/shield-protection.png" width="100" />
            </Box>
        </Box>
    );
};

export default Splash;
