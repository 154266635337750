import * as React from "react";

import { debounce, camelCase } from "lodash";
import { Box, Button, MaskedInput, TextInput } from "grommet";

const { Component } = React;

const AnswerableOption = ({ option, changed, value, atomic, disabled }) => {
    value = value == null ? "" : value;

    let [internalValue, setValue] = React.useState(value);

    if (option.type == 0) {
        value = value === "true" ? true : value;
        value = value === "false" ? false : value;
        value = value === "" ? false : value;

        if (value != internalValue) {
            setValue(value);
        }
    }

    let timer = 10;
    switch (option.type) {
        case 0: {
            timer = 100;
            break;
        }
        case 1: {
            timer = 750;
            break;
        }
        case 2: {
            timer = 750;
            break;
        }
    }

    const changeHandler = function (v) {
        changed(v);
    };

    const debounceHandleChange = React.useMemo(() => debounce(changeHandler, timer), []);

    let handleChange = (e) => {
        let v = e.target.value;
        setValue(v);
        debounceHandleChange(v);
    };

    let handleNumberChange = (e) => {
        setValue(e.target.value);
        debounceHandleChange(e.target.value);
    };

    let handleAtomicChange = (v) => () => {
        setValue(v);
        debounceHandleChange(v);
    };

    switch (option.type) {
        case 0: {
            return (
                <>
                    {atomic && (
                        <>
                            {option.text && <strong>{option.text}</strong>}
                            <Box gap="small" direction="row">
                                <Button className="text-center" label="Yes" primary active={internalValue !== true} disabled={disabled} onClick={handleAtomicChange(true)}></Button>
                                <Button className="text-center" label="No" primary active={internalValue !== false} disabled={disabled} onClick={handleAtomicChange(false)}></Button>
                            </Box>
                        </>
                    )}
                    {!atomic && <Button className="text-center" label={option.text} fill primary active={!internalValue} disabled={disabled} onClick={handleAtomicChange(!internalValue)}></Button>}
                </>
            );
        }
        case 1:
            return (
                <div>
                    {option.text && <strong>{option.text}</strong>}
                    <TextInput
                        type="text"
                        autoComplete={option.autoComplete || "on"}
                        style={{ minHeight: "34px", maxHeight: "150px", height: "34px" }}
                        name={camelCase(option.text)}
                        value={internalValue}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                </div>
            );
        case 2:
            return (
                <div>
                    {option.text && <strong>{option.text}</strong>}
                    <MaskedInput
                        mask={[{ length: [1, 2], regexp: /^\d{1,2}$/, placeholder: "--" }, { fixed: "." }, { regexp: /\d$/, placeholder: "-" }]}
                        type="number"
                        value={internalValue}
                        disabled={disabled}
                        onChange={handleNumberChange}
                    />
                </div>
            );
    }
};

export default AnswerableOption;
