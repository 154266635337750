import { normalizeColor } from "grommet/utils";
import * as React from "react";
import styled from "styled-components";
import Theme from "../utils/Theme";
import { useDarkMode } from "./DarkModeSwitch";

const MenuItemContainer = styled.div`
    background-color: ${({ darkMode }) => normalizeColor("background-back", Theme, darkMode)};
    border-radius: 15px;
    transition: background-color 0.3s ease;
    padding: 1rem;
    margin: 1rem;
    width: 136px;

    &.active {
        border: 4px solid ${(darkMode) => normalizeColor("brand", Theme, darkMode)};
    }

    &:hover {
        background-color: ${({ darkMode }) => normalizeColor("background-front", Theme, darkMode)};
        cursor: pointer;
    }
`;

const MenuItemImageBlock = styled.div`
    position: relative;
    display: block;
    width: 80px;
    margin: auto;
`;

const MenuItemImage = styled.img`
    max-width: 80px;
    max-height: 80px;
    display: block;
`;

const MenuItemName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
`;

const MenuItemDescription = styled.div`
    text-align: center;
    color: ${(props) => props.color};
    font-size: 0.7rem;
`;

const MenuItem = ({ name, description, image, subtext = "", active = false }) => {
    const [isDarkMode] = useDarkMode();

    return (
        <MenuItemContainer darkMode={isDarkMode} className={active && "active"}>
            <MenuItemImageBlock>
                <MenuItemImage src={image} alt="" />
            </MenuItemImageBlock>
            <MenuItemName>{name}</MenuItemName>
            <MenuItemDescription>{description}</MenuItemDescription>
            {subtext && <MenuItemDescription>{subtext}</MenuItemDescription>}
        </MenuItemContainer>
    );
};

export default MenuItem;
