import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

async function getTokenPopup(account) {
    let request = {
        ...loginRequest,
        account: account,
    };

    return await msalInstance.acquireTokenSilent(request).catch(async (error) => {
        if (error) {
            return msalInstance.acquireTokenPopup(request).catch((error) => {
                return msalInstance.acquireTokenRedirect(request).catch((error) => {
                    sessionStorage.clear();
                    window.location.reload();
                });
            });
        } else {
            sessionStorage.clear();
            window.location.reload();
            return error;
        }
    });
}

axios.interceptors.request.use(
    async function (config) {
        const account = msalInstance.getActiveAccount();
        if (account) {
            const token = await getTokenPopup(account);
            if (config.url.indexOf(IdentityServerUrl) == 0) {
                config.headers["Authorization"] = `Bearer ${token.accessToken}`;
            } else if (config.url.indexOf(ServiceManagerUrl) == 0) {
                config.headers["Authorization"] = `IdentityBearer ${token.accessToken}`;
            } else if (config.url.indexOf(PresenceUrl) == 0) {
                config.headers["Authorization"] = `IdentityBearer ${token.accessToken}`;
            }
        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(function (response) {
    if (response.status == 401) {
        const account = msalInstance.getActiveAccount();
        msalInstance.acquireTokenRedirect({
            ...loginRequest,
            account: account,
        });
    }
    return response;
});

export let TrimFields = (obj) => {
    Object.keys(obj).map((k) => obj[k] && (obj[k] = obj[k].trim()));
    return obj;
};

export default axios;
