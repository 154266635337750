import axios from "./HttpClient";

const presenceURL = PresenceUrl;

export function ListQuarantineSubjects() {
    return axios.get(`${presenceURL}api/quarantine/$list`).then((res) => res.data.result);
}

export function QuarantineMe() {
    return axios.post(`${presenceURL}api/quarantine/$quarantineMe`).then((res) => res.data.result);
}
