import * as React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clamp, every, some } from "lodash";
import AnswerableOption from "./AnswerableOption";
import { format } from "date-fns";
import AttachmentUpload from "./AttachmentUpload";
import ReactMarkdown = require("react-markdown");
import { Button, Text } from "grommet";

export function AnswerableQuestion({ questionnaire, question, handleOptionChange, deleteSet, addSet, attachmentUploaded, deleteAttachment, user }) {
    return (
        <>
            <div>
                <div className="d-flex" style={{ alignItems: "flex-end", justifyContent: "space-between" }}>
                    <h3 style={{ fontWeight: 300, marginBottom: "1rem", marginTop: "2rem" }}>{question.text}</h3>
                    {question.required && (
                        <Text size="small" color="status-critical">
                            Required
                        </Text>
                    )}
                </div>
                <div>
                    <ReactMarkdown>{question.subtext}</ReactMarkdown>
                </div>
            </div>
            {question.allowSets && (
                <div>
                    {question.sets.map((set, setKey) => (
                        <div key={`q${question.id}s${setKey}`}>
                            <div>
                                <div>
                                    <QuestionOptions question={question} options={set.options} handleOptionChange={handleOptionChange} setId={set.id} disabled={questionnaire.locked}></QuestionOptions>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div>
                                        <Button
                                            /* @ts-ignore */
                                            icon={<FontAwesomeIcon icon={["fad", "xmark"]}></FontAwesomeIcon>}
                                            reverse
                                            color="danger"
                                            onClick={() => deleteSet(question.id, set.id)}
                                            className="ml-2"
                                        ></Button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    ))}
                    <div>
                        <div>
                            <Button
                                reverse
                                fill
                                color="success"
                                onClick={() => addSet(question.id)}
                                className="ml-2"
                                disabled={questionnaire.locked}
                                icon={<FontAwesomeIcon icon={["fad", "plus"]}></FontAwesomeIcon>}
                                label="Add an answer"
                            ></Button>
                        </div>
                    </div>
                </div>
            )}
            {!question.allowSets && (
                <>
                    <div>
                        <QuestionOptions question={question} options={question.options} handleOptionChange={handleOptionChange} disabled={questionnaire.locked}></QuestionOptions>
                    </div>
                </>
            )}
            {!questionnaire.locked && (
                <div>
                    {question.allowUploads && user.hasQuestionnaireAccess(questionnaire.id, 8) && (
                        <AttachmentUpload questionId={question.id} questionnaireId={questionnaire.id} done={attachmentUploaded}></AttachmentUpload>
                    )}
                </div>
            )}
            {question.allowUploads && user.hasQuestionnaireAccess(questionnaire.id, 8) && (
                <div>
                    {question.attachments.map((attachment, attachmentKey) => (
                        <div className="mr-2" key={`q${question.id}a${attachmentKey}`}>
                            <Button color="info" icon={<FontAwesomeIcon icon={["fad", "file"]}></FontAwesomeIcon>} label={attachment.name} reverse href={`/api/Attachments/${attachment.id}`}></Button>
                            {!questionnaire.locked && (
                                <Button
                                    /* @ts-ignore */
                                    icon={<FontAwesomeIcon icon={["fad", "xmark"]}></FontAwesomeIcon>}
                                    className="d-xl-block d-none"
                                    color="danger"
                                    reverse
                                    onClick={() => deleteAttachment(question.id, attachment.id)}
                                ></Button>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {question.allowComments && user.hasQuestionnaireAccess(questionnaire.id, 16) && (
                <div>
                    {question.comments.map((comment, commentKey) => (
                        <div className="mr-2" key={`q${question.id}c${commentKey}`}>
                            <span>
                                {format(comment.timestamp, "DD/MM/YYYY HH:mm")} - {comment.username} - {comment.text}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

function QuestionOptions(props) {
    let { question, options, handleOptionChange, setId, disabled } = props;
    let atomic = some(options, (o) => o.type === 0) && (!every(options, (o) => o.type === 0) || options.length == 1);
    return (
        <>
            <QuestionGrid>
                {options.map((option, optionKey) => (
                    <div
                        key={`q${question.id}o${optionKey}`}
                        style={{
                            gridColumn: "span " + (option.type === 1 ? Math.floor(clamp(6 / question.options.length, 1, 6)) : 1),
                        }}
                    >
                        {/* {question.key == "temperature" && (
                            <TemperatureCapture
                                option={option}
                                value={option.value}
                                changed={(value) => handleOptionChange(value, option, question.id, setId)}
                                atomic={atomic}
                                disabled={disabled}
                            ></TemperatureCapture>
                        )} */}
                        {/* {question.key != "temperature" && (
                            )} */}
                        <AnswerableOption
                            option={option}
                            value={option.value}
                            changed={(value) => handleOptionChange(value, option, question.id, setId)}
                            atomic={atomic}
                            disabled={disabled}
                        ></AnswerableOption>
                    </div>
                ))}
            </QuestionGrid>
            {question.multipleOptions &&
                every(question.options, {
                    type: 0,
                }) && (
                    <Text size="small" color="text-weak">
                        Choose multiple
                    </Text>
                )}
            {!question.multipleOptions && every(question.options, { type: 0 }) && (
                <Text size="small" color="text-weak">
                    Choose one
                </Text>
            )}
        </>
    );
}

let Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

let QuestionGrid = styled.div`
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(6, 1fr);
    }
`;

let TemperatureCapture = ({ option, value, changed, atomic, disabled }) => {
    const [temp, setTemp] = React.useState(+value || 0);

    value = value || "";

    const [digits, setDigits] = React.useState(Math.min(value.length, 3));

    let enterDigits = (digit) => () => {
        if (digits == 0) {
            setTemp(digit * 10);
            setDigits(1);
        } else if (digits == 1) {
            setTemp(temp + digit);
            setDigits(2);
        } else if (digits == 2) {
            setTemp(temp + digit / 10);
            setDigits(3);
        }
    };

    let dot = () => {};

    let back = () => {
        if (digits == 3) {
            setTemp(Math.floor(temp));
            setDigits(2);
        } else if (digits == 2) {
            setTemp(Math.floor(temp / 10) * 10);
            setDigits(1);
        } else if (digits == 1) {
            setTemp(0);
            setDigits(0);
        }
    };

    React.useEffect(() => {
        changed && changed(temp);
    }, [temp]);

    return (
        <div>
            <div>{temp.toFixed(1)}</div>
            <Grid>
                <Button label="1" disabled={disabled} onClick={enterDigits(1)}></Button>
                <Button label="2" disabled={disabled} onClick={enterDigits(2)}></Button>
                <Button label="3" disabled={disabled} onClick={enterDigits(3)}></Button>
                <Button label="4" disabled={disabled} onClick={enterDigits(4)}></Button>
                <Button label="5" disabled={disabled} onClick={enterDigits(5)}></Button>
                <Button label="6" disabled={disabled} onClick={enterDigits(6)}></Button>
                <Button label="7" disabled={disabled} onClick={enterDigits(7)}></Button>
                <Button label="8" disabled={disabled} onClick={enterDigits(8)}></Button>
                <Button label="9" disabled={disabled} onClick={enterDigits(9)}></Button>
                <Button label="." disabled={disabled} onClick={dot}></Button>
                <Button label="0" disabled={disabled} onClick={enterDigits(0)}></Button>
                {/* @ts-ignore */}
                <Button icon={<FontAwesomeIcon icon={["fad", "delete-left"]}></FontAwesomeIcon>} disabled={disabled} onClick={back}></Button>
            </Grid>
        </div>
    );
};

export default AnswerableQuestion;
