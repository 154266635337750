import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import MenuItem from "../components/MenuItem";
import { debounce } from "lodash";
import { useCampaign } from "../utils/CampaignStore";
import { QuestionnaireActions, StartImpersonateQuestionnaire, StartQuestionnaire } from "../utils/ServiceManagerAPI";

import { utcToZonedTime } from "date-fns-tz";
import formatRelative from "date-fns/formatRelative";
import { Link, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import SkeletonWrapper from "../components/SkeltonWrapper";
import { Anchor, Box, TextInput } from "grommet";
import StyledModal from "../components/StyledModel";
import { useUser } from "../utils/AccountStore";
import { GetIdentityGroupMembers, GetIdentityGroups, SearchUsers } from "../utils/IdentityAPI";
import useLocalStorage from "../utils/LocalStorage";
import SearchGroups from "./SearchGroups";
import SearchIndividual from "./SearchIndividual";

let Impersonate = () => {
    let [loading, setLoading] = React.useState(true);

    let campaign = useCampaign();

    React.useEffect(() => {
        if (campaign.id) {
            setLoading(false);
        }
    }, [campaign]);

    let { path, url } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={path} exact>
                    <h2 style={{ minWidth: "100px" }}>
                        <Link to="/screening" component={Anchor}>
                            {/* @ts-ignore */}
                            <FontAwesomeIcon icon={["fad", "circle-chevron-left"]}></FontAwesomeIcon>
                        </Link>{" "}
                        <span style={{ marginLeft: "1rem" }}>Screen Others</span>
                    </h2>
                    <SkeletonWrapper loading={loading}>
                        <div style={{ minHeight: "100px" }}>
                            {campaign && (
                                <div style={{ display: "flex" }}>
                                    <Link to={`${path}/individual`} component={Anchor}>
                                        <MenuItem name={"Individual"} description={"Search"} image={"/images/individual.png"}></MenuItem>
                                    </Link>
                                    <Link to={`${path}/groups`} component={Anchor}>
                                        <MenuItem name={"Group"} description={"Search"} image={"/images/groups.png"}></MenuItem>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </SkeletonWrapper>
                </Route>
                <Route path={`${path}/individual`}>
                    <SearchIndividual></SearchIndividual>
                </Route>
                <Route path={`${path}/groups`}>
                    <SearchGroups></SearchGroups>
                </Route>
            </Switch>
        </div>
    );
};

export default Impersonate;
